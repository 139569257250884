@import "antd/dist/antd.variable.min.css";

/* style for application  */
.mainButtonForApplication {
  background-color: transparent;
  width: 100%;
  height: 66px;
  border-radius: 7px;
  margin-bottom: 20px;
  border: white !important;
}
.mainButtonForApplication:hover {
  background-color: var(--application-btn) !important;
  border: none !important;
}
.mainButtonForApplicationName {
  font-family: Helvetica, ProductSans;
  margin: 0px;
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  color: var(--application-btn-text);
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainButtonForApplicationIcon {
  margin-top: 10px;
}

.CardForJobPrefixCode {
  /* width: 784px; */
  width: 100%;
  height: 150px;
  border-radius: 7px;
}

.CardForJobInputField {
  /* width: 736px; */
  width: 100%;
  height: 60px;
  border-radius: 7px;
  margin-left: 24px;
  margin-bottom: 35px;
  margin-right: 24px;
}

.CardForJobInputField::placeholder {
  font-size: 16px;
  line-height: 19.41px;
  font-weight: 400;
  color: var(--headings-color);
}

.cardPrefixLabelName {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  margin-bottom: 10px;

  margin-top: 21px;
  margin-left: 24px;
}
.CardForJobPrefixCodeName {
  color: var(--headings-color);
  font-weight: 400;
  line-height: 24.26px;
  border-radius: 7px;
  font-size: 20px;
  margin-top: 21px;
  margin-left: 24px;
}

.SaveButtonForPrefixCode {
  width: 146px;
  height: 50px;
  border-radius: 8px;
  margin-top: 24px;
}
/* end of application style  */

/* email template page  */
.EmailAddTemplateBtn {
  margin-right: 20px;
}

.EmailTempBackBtn {
  background-color: transparent;
}

.EmailTemplatesCard {
  width: 100%;
  border-radius: 7px;
  margin-left: 5px;
  margin-bottom: 16px;
}

.EmailTemplatesBackBtn {
  color: var(--headings-color);
  font-size: 24px;
  line-height: 29.11px;
  font-weight: 400;
  margin: 25px 20px 30px 20px;
  width: 30px;
  height: 30px;
}

.EmailTemplatesAddBtn {
  margin-top: 20px;
  width: 180px;
  height: 46px;
  border-radius: 6px;
}

.EmailAddTemplateName {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-top: 1px;
}

.EmailTempTblHeading {
  color: var(--headings-color);
  font-weight: 400;
  font-size: 20px;
  line-height: 24.26px;
  margin: 0px;
  padding: 0px;
}

.EmailTempTblClmData {
  color: var(--headings-color);
  font-weight: 400;
  line-height: 19.41px;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.EmailTempTblClmEnableBtn {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-left: 5px;
}

.EmailAddTemplateEdit {
  color: var(--primaryColor);
  margin-bottom: 3px;
  padding: 15px 0px 12px 12px;
}

.EmailAddTemplateDelete {
  color: var(--primaryColor);
  margin-bottom: 5px;
  padding: 15px 0px 12px 12px;
}

.EmailAddTemplateIcons {
  padding-top: 12px;
  padding-left: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
/* end of email template page  */

/* addtemplatemodal  */

.textAreaIconsCard {
  z-index: 10;
  height: 50px;
  box-shadow: 0px 0px 10px lightgray;
}
.EmailModalLabel {
  color: var(--headings-color);
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
}

.textAreaIcons {
  margin-left: 20px;
  margin-top: 10px;

  /* border: 2px solid red; */
}

.paperbtn {
  width: 137px;
  height: 46px;
  margin-top: 0px;
}

.paperBtnName {
  font-weight: 500px;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 0px 0px 15px;
}
.paperBtnNameTemp {
  font-weight: 500px;
  font-size: 16px;
  line-height: 20px;
}

/* end of add template modal  */

/* tags page */
.TagTemplatesBackBtn {
  color: var(--headings-color);
  font-size: 24px;
  line-height: 29.11px;
  font-weight: 400;
  margin: 20px 20px 30px 15px;
  width: 30px;
  height: 30px;
}

.TabsTableHeading {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 24px;
}

.TagTemplatesBackBtnIcon {
  background-color: transparent !important;
}

.TagsCard1 {
  height: 70px;
  border-bottom: 1px solid #e0e0e0;
}

.TagCard2 {
  background-color: var(--theme);
  height: 60vh;
}
/* .TagCardName {
  font-family: ProductSans;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-left: 2px;
  color: var(--body-text-color);
} */

.tagsSelectTagsInput {
  width: 96%;
  display: block;
  position: relative;
  margin: 0px auto;
  border-radius: 7px;
}

/* souce of the lead page  */
.SrcOfLeadSelectTagsInput {
  width: 100%;
  margin-left: 35px;
  border-radius: 7px;
}

.SourceofTheLeadBackBtn {
  color: var(--headings-color);
  font-size: 24px;
  line-height: 29.11px;
  font-weight: 400;
  margin: 20px 20px 30px 15px;
  width: 30px;
  height: 30px;
}

.SourceOfLeadSelectedTags {
  color: var(--primaryColor);
  font-family: Helvetica, ProductSans;
  font-style: normal;
  border-radius: 18px;
  margin-top: 30px;
  padding: 10px 20px 10px 20px;
  background-color: var(--secondaryColor);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
}

.SourceOfTheLeadCard1 {
  margin-top: 31px;
  margin-left: 24px;
  margin-bottom: 24px;
}
.SourceOfTheLeadCard1Name {
  font-weight: 500;
  font-size: 20px;
  line-height: 24.26px;
}
.tagsSelectedTags {
  background-color: var(--menu-tab-color);
}
/* end of source of the lead  */

/* candidate selection and rejection list  */
.CanidateHeadingName {
  font-weight: 500;
  line-height: 24.26px;
  font-size: 20px;
  margin-top: 24px;
  /* margin-left: 24px; */
  margin-bottom: 26px;
  width: 2500px;
}

.CandidateCard1 {
  box-sizing: border-box;
}

.CandidateSelectCardText {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-size: 16px !important;
  line-height: 19px;
  font-weight: 300;
  color: var(--body-text-color);
}

.CandidateOther {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  color: var(--body-text-color);
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}
/* end of canidate selection */

/* styling for job listing page  */
.JobListingHeadingName {
  font-weight: 500;
  font-size: 20px;
  line-height: 24.26px;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 26px;
}

.jobListingCard1 {
  background-color: red;
}

/* .EnableDisableBtn {
  position: absolute;
  right: 60px;
} */

.BtnSpace {
  margin-right: 20px;
}

.jobListingIndRow {
  margin-top: 20px;
  border-bottom: 1px solid #e0e0e0;
}

/* end of styling for job listing page  */

/* job description page  */

.JobDescBackBtn {
  color: var(--headings-color);
  font-size: 24px;
  line-height: 29.11px;
  font-weight: 400;
  margin: 25px 20px 30px 20px;
  width: 30px;
  height: 30px;
}

.JobDescCancelBtn {
  background-color: transparent;
  border: 2px solid var(--primaryColor);
  height: 46px;
  width: 103px;
  border-radius: 6px;
}

.jobDescEnableBtn {
  color: #000000;
  line-height: 19h1px;
  font-size: 16px;
  font-weight: 400;
}

.JobDescTblCnt {
  color: var(--headings-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19.41px;
}

.JobDescTemplatesAddBtn {
  margin-top: 20px;
  width: 210px;
  height: 46px;
  border-radius: 6px;
}

.AddJobDescBtnName {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
}

.AddJobDescBtn {
  position: relative;
  left: 57%;
}
.AddDescModalName {
  padding: 0px;
  margin: 0px;
  margin-bottom: -20px;
  font-weight: 500;
  font-size: 20px;
  color: var(--headings-color);
  margin-top: 5px;
}

.AddJobDeescTtlName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24.26px;
  color: var(--headings-color);
  padding: 0px;
  margin: 0px;
}
/* end of job desc page  */
/* end of tags page  */
.btnPrimary {
  font-size: 16px;
  height: 48px;
  width: 181px;
  border-radius: 7px;
}
.btnSecondary {
  background-color: transparent !important;
  font-size: 16px;
  height: 48px;
  width: 181px;
  border: 2px solid var(--primaryColor);
  border-radius: 7px;
  color: var(--primaryColor) !important;
}

.drawerRowStyling {
  padding: 0px 20px 0px 20px;
}
