.breadCrumContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addButton {
  font-size: 16px;
  line-height: 24px;
  height: 46px;
  width: 214px;
  border-radius: 6px;
  float: right;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
.inttitle {
  margin-right: 16px;
  float: left;
}

.ContentTextBlue {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.98px;
}
.infoTextColor {
  font-family: Helvetica, ProductSans;
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-weight: 400;
  color: var(--body-text-color);
  margin: 0px;
}

.summaryInfoMainText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--headings-color);
  margin: 0px;
}
.modalText {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--headings-color);
  margin: 0px;
}

.infoTextForExperience {
  color: var(--body-text-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.paddingForInfo {
  padding: 0px 24px 24px 24px;
}
.addIconsAlig {
  text-align: center;
  width: 50%;
  display: block;
  position: relative;
  margin: 40px auto 0px auto;
}
.candidateSummaryLeftMainCont {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
.candidateSummaryLeftSubCont {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: var(--body-text-color);
}
.candidateSummaryLeftSubContBold {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
}

.drawerFormText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
}
.personalDetails {
  padding-left: 16px;
}
.interviewHeading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--headings-color);
}
.interviewSubHeading {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: var(--body-text-color);
}
.savebtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  margin-right: 19px;
}
.interviewbtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
}
.footerContainer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
.bulletingPoints {
  font-size: 14px;
  font-weight: 400;
  color: var(--headings-color);
}
.answersText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--headings-color);
}

.btnPrimary {
  font-size: 16px;
  height: 48px;
  width: 181px;
}
.btnSecondary {
  font-size: 16px;
  height: 48px;
  width: 181px;
}

.infoTextParagrapg {
  margin-top: 3px;
  color: var(--headings-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.clientCompTaskTabHeading {
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  color: var(--subHeadings-color);
}

.clientCompJobDetails {
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.41px;
  color: var(--headings-color);
}

.statusText {
  font-family: Helvetica, Product Sans Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  color: var(--headings-color);
}
.statusSubText {
  font-family: Helvetica, Product Sans Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  color: var(--body-text-color);
  margin-top: 11px;
}
.statusTextForDate {
  font-size: 14px;
  color: var(--body-text-color);
}

.textarea {
  width: 100% !important;
}
.candidateSummaryCardSubTitle {
  font-style: normal;
  font-family: Helvetica, ProductSans;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
  margin: 0px;
  color: var(--card-text-color);
}
.cards {
  width: 250px;
  height: 128px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.19);
}
.candidateSummaryCardTitle {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 23px !important;
  text-align: center;
  margin-bottom: 16px;
}
