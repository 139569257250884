/*  */
/* break points for screen in css
//for mobile
min-width: 320px;
max-width: 480px;
//for tablet
min-width: 481px;
max-width: 768px;
//for laptop
min-width: 769px;
max-width: 1023px;
//for desktop
min-width: 1024px;
max-width: 1200px;
//for tv/extra large
min-width: 1201px; */
/* task page css start */

.taskBtn {
  background-color: #e0e0e0;
  border: none;
  box-shadow: none;
}
.taskColor {
  color: #1e1e1e;
}
.taskBtnTextColor {
  color: #f6f8fc;
}
.breadcrumbText {
  color: var(--body-text-color);
}
.taskBtn:focus {
  background-color: #e0e0e0;
  color: var(--primaryColor) !important;
  border: none;
}
.taskBackClrBtn {
  background-color: var(--primaryColor);
  color: white !important;
  border: none;
}

/* task page css end */

.marginBottomRemove {
  margin: 0px;
}
.coverBoady {
  overflow: scroll;
  /* overflow-x: scroll;
  overflow-y: hidden; */
}
.wishesCard {
  background-color: var(--secondary-card-color);
  padding: 0px 15px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px lightgray;
  color: var(--body-text-color);
}

.coverBoady::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}
.coverBoady::-webkit-scrollbar-track {
  background: transparent;
}
.externalJJobScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.overflow {
  overflow: scroll;
  /* overflow-y: scroll;
  overflow-x: scroll; */
}
.overflow::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
.textBreak {
  overflow-wrap: break-word !important;
}
.jdScroll {
  overflow-y: auto;
  /* height: 50%; */
  width: 100%;
}

.textEllipsis1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textAlignment {
  text-align: left;
}
.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textEllipsis:hover {
  overflow: visible;
}
.stepsText {
  text-align: center;
  color: white;
  font-weight: 500;
  line-height: 21px;
}
.stepsBackgroundActive {
  height: 20px;
  width: 20px;
  background-color: var(--primaryColor);
  border-radius: 2px;
}
.stepsBackgroundInActive {
  height: 20px;
  width: 20px;
  background-color: #cbc8c8;
  border-radius: 2px;
}

.tableDataLinkText {
  color: var(--primaryColor);
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.headingText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--main-heading-text-color);
}
.subHeadingText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--subHeadings-color);
}
.textNormal {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
}
.lightText {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.lightTextForUpload {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.darkText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.subHeadingTextNormal {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}

.textColorHover {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--body-text-color);
}
.textColorHover:hover {
  color: var(--primaryColor);
}
.mainHeadingText {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  color: var(--main-heading-text-color);
}
.jobReq {
  font-family: Helvetica, "ProductSans";
  font-weight: 600;
  size: 24px;
  line-height: 29.11px;
}
.headingText2 {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--headings-color);
}
.tableHeadingsColor {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color) !important;
}
.headingSourcing {
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.26px;
  color: var(--headings-color) !important;
}
.linkForSourcing {
  margin-bottom: 25px;
  padding-left: 8px;
  font-family: Helvetica, Product Sans Medium;
  padding-top: 24px;
}
.buttonForSourcing {
  margin-bottom: 25px;
  /* padding-left: 24px; */
  font-family: Helvetica, "Product Sans Medium";
  padding-top: 24px;
}
.srcOfLeade {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.tableData {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--table-text-color);
}
.profileNameText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: var(--headings-color);
}

.tableHeadingText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--body-text-color);
}
.tableHeadingTextForEmailTemplate {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--table-heading-color);
  /* color: var(--body-text-color); */
}
.tableHeadingTextData {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.popUpHeadingText {
  color: var(--headings-color);
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
  line-height: 24px;
}
.deleteConfiTextColor {
  color: var(--body-text-color);
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.removeLocation {
  font-family: Helvetica, Product Sans Medium;
  color: var(--error-text-color);
  font-size: 500;
  font-size: 16px;
  font-style: normal;
}
.removeLocationbtn {
  background-color: var(--error-text-color) !important ;
}
.removeLocationbtn:hover {
  color: var(--error-text-color);
  background-color: var(--error-text-color) !important ;
}

/* .removeBtnHover:hover{
  font-family: Product Sans Medium;
  background-color: red !important;
  color: #f83a3a !important;
  font-size: 500;
  font-size: 16px;
  font-style: normal;
  margin-top: 3px;
  margin-right: 7px;
  border-color:#f83a3a !important ;
} */

.addLocation {
  font-family: Helvetica, Product Sans Medium;
  font-size: 500;
  font-size: 16px;

  font-style: normal;
  color: var(--primaryColor);
}
.chosenFileTextColor {
  font-family: Helvetica, ProductSans;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--nofile-text-color);
}
.tableJobDescriptionTextData,
.tableEmailDescriptionTextData {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--table-text-color);
}
.infoTextColor {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.infoTextColorBold {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.headingSettingsText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* color: var(--body-text-color); */
  color: var(--headings-color);
}
.tabsText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* color: var(--body-text-color); */
  color: var(--menu-tabs-color);
}
.textNormalForBillingInvoice {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
  /* color: var(--body-text-color); */
}

.ApplicationTemplatesName {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  color: var(--headings-color);
  font-size: 24px;
  line-height: 29.11px;
  font-weight: 500;
  margin-top: 29px;
  margin-left: 24px;
  margin-bottom: 30px;
}
.applicationCardName {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-left: 2px;
  color: var(--headings-color);
}

/*  */
.filterFooterButtonPrimary {
  height: 46px;
  width: 137px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 19.41px;
  font-weight: 500px;
}
.filterDrop {
  width: 106px;
  height: 37px;
  margin-right: 16px;
  float: left;
  font-size: 16px;
}

.filterFooterButtonSecondary {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  height: 46px;
  width: 137px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 19.41px;
  font-weight: 500px;
  background: transparent;
}
.filterFooterButtonSecondary:hover {
  color: var(--theme);
}
.footerShadow {
  padding: 15px;
  position: fixed;
  bottom: 0px;
  background-color: var(--drawer-bg-color);
  box-shadow: var(--box-shadow);
}
.footerShadowForTemplete {
  padding: 15px;
  bottom: 0px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: var(--theme);
  box-shadow: var(--box-shadow);
}

.inttitle {
  margin-right: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  float: left;
}

.filterButton {
  height: 39px;
  width: 106px;
  margin-right: 16px;
  float: left;
  border: none;
  color: var(--primaryColor);
  background-color: var(--inverse-secondaryColor);
  font-family: Helvetica, ProductSans;
}
.filterButtonBorder {
  border: 1px solid var(--dr-border-color) !important;
}
.jobReqFilter {
  color: var(--primaryColor);
  background-color: var(--secondaryColor);
  width: 106px;
  height: 37px;
  border: none;
}
.jobReqSort {
  color: var(--primaryColor);
  background-color: var(--secondaryColor);
  width: 192px;
  height: 37px;
  border: none;
}
.jobReqTitle {
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.98px;
}
.sourcingCandiTitle {
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.98px;
  color: var(--primaryColor) !important;
}
.linksSourcing {
  font-family: Helvetica, Product Sans Medium;
  color: var(--primaryColor) !important;
}
.rowSourcing {
  padding-top: 18px;
  padding-left: 24px;
  margin-bottom: 30px;
}
.cardSourcing {
  width: 100%;
  border: 1px solid #bcb8b8;
  margin-left: 6px;
  padding: 22px;
  margin-right: 31px;
}
.sourcingTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  margin-bottom: 20px;
  color: var(--subHeadings-color);
  font-family: Helvetica, Product Sans Medium;
}
.sourcingBodyTitle {
  font-size: 14px;
  line-height: 16.98px;
  margin-bottom: 9px;
  color: var(--body-text-color);
  font-family: Helvetica, Product Sans Medium;
}
.jobReqTable {
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
}
.filterButton:hover {
  border: none !important;
  color: var(--primaryColor);
  background-color: var(--inverse-secondaryColor) !important;
}
.filterButtonBorder:hover {
  border: 1px solid var(--dr-border-color) !important;
}
.selector {
  background-color: #044123;
}
.sortBtn {
  color: var(--primaryColor) !important;
  background-color: var(--inverse-secondaryColor) !important;
  border: none !important;
  font-family: Helvetica, ProductSans;
}
.sortBtnBorder {
  border: 1px solid var(--dr-border-color) !important;
}
.sortBtn:hover {
  color: var(--primaryColor) !important;
  background-color: var(--inverse-secondaryColor) !important;
  border: none !important;
}
.sortBtnBorder:hover {
  border: 1px solid var(--dr-border-color) !important;
}

.footerButtonAddCandidateDrawerPrimary {
  height: 46px;
  width: 180px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.83px;
  color: var(--theme);
}
.footerButtonAddCandidateDrawerPrimary:hover {
  color: var(--primary-hover-button-color) !important;
}

.footerButtonAddCandidateDrawerSecondary {
  height: 46px;
  width: 180px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.83px;
  background: transparent;
}
.footerButtonAddCandidateDrawerSecondary:hover {
  color: var(--secondary-hover-button-color) !important;
}

.buttonSettingsPrimary {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  color: var(--theme);
  height: 50px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
}
.buttonSettingsPrimary:hover,
.buttonSettingsPrimary:focus {
  /* background-color: var(--primary-button-color) !important; */
  color: var(--primary-hover-button-color) !important;
}
.buttonSettingsSecondary {
  font-family: Helvetica, Product Sans Medium;
  height: 50px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
  font-style: normal;
  background: transparent;
}
.buttonSettingsSecondary:hover {
  /* background-color: var(--secondary-hover-button-color) !important; */
  color: var(--secondary-hover-button-color) !important;
}
.coursorPointer {
  cursor: pointer;
}

.breadcrumbBottomMargin {
  margin-bottom: 10px;
}
.buttonSettingsChoosePrimary {
  height: 43px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
  background-color: var(--nofile-button-color);
}
.buttonSettingsChoosePrimary:hover {
  color: var(--theme) !important;
  background-color: var(--nofile-button-color);
}
.buttonSettingsDataImportPrimary {
  height: 43px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
  color: var(--theme);
}
.buttonSettingsDataImportPrimary:hover {
  color: var(--primary-hover-button-color) !important;
}

.buttonSettingsDataImportSecondary {
  height: 43px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
  background: transparent;
}
.buttonSettingsDataImportSecondary:hover {
  color: var(--secondary-hover-button-color) !important;
}

.modalHeading {
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.83px;
  color: var(--headings-color);
}
.taskAddBtn {
  width: 214px;
  height: 46px;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.modalRowStyling {
  padding: 0px 20px 0px 20px;
}

.marginTop {
  /* margin-top: 9px; */
  margin-top: 12px;
  margin-bottom: 24px;
}

.jobDetailParagraph {
  color: var(--table-text-color);
  margin-top: 0px;
  font-family: Helvetica, Product Sans Medium;
}

.JobInfoBodyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.98px;
  color: var(--body-text-color);
  font-family: Helvetica, ProductSans;
  font-style: normal;
  margin-bottom: 20px;
}

.pageHeadingForTable {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--headings-color);
  font-family: Helvetica, ProductSans;
}

.formInputLabel {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 19px; */
  color: var(--body-text-color);
}

.filterHeadingName {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: var(--primaryColor);
}

.newIcon {
  transform: translateY(1px);
  margin-left: 14px;
}
.filterAndSortTextStyle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 4px;
}

.dataImportBtnCandidate {
  font-family: Helvetica, ProductSans;
  font-weight: 400px;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
  background-color: var(--primaryColor);
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: 25rem;
}

.dataImportBtnCandidate:hover {
  background-color: var(--primaryColor) !important;
}

.dataImportBtnCandidaterecruit {
  font-family: Helvetica, ProductSans;
  font-weight: 400px;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
  background-color: var(--primaryColor);
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: 40px;
  margin-right: 10px;
}
.dataImportBtnCandidaterecruit:hover {
  background-color: var(--primaryColor) !important;
}
.dataExportBtnCandidate {
  font-family: Helvetica, ProductSans;
  font-weight: 400px;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
  background-color: var(--primaryColor);
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.dataExportBtnCandidate:hover {
  background-color: var(--primaryColor) !important;
}
.intaddButton {
  font-family: Helvetica, ProductSans;
  font-weight: 400px;
  font-size: 16px;
  line-height: 19px;
  height: 46px;
  background-color: var(--primaryColor);
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  float: right;
}
.intaddButton:hover {
  background-color: var(--primaryColor) !important;
}

.intaddButtonp {
  font-family: Helvetica, ProductSans;
  font-weight: 400px;
  font-size: 16px;
  background-color: var(--primaryColor);
  cursor: default;
}
.intaddButtonp:hover {
  background-color: var(--primaryColor) !important;
}

.textClrForMenu {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.HeadingFormDesign {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  color: var(--headings-color);
  margin-bottom: 16px;
  margin-top: 10px;
}

.activeTag {
  background-color: #d2efcd;
  color: #044123;
  padding: 5px 26px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-family: Helvetica, ProductSans;
}

.inactiveTag {
  background-color: #ffd9d9;
  padding: 5px 20px;
  color: #f83a3a;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-family: Helvetica, ProductSans;
}

.informationPersonName {
  color: var(--primaryColor);
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  font-family: Helvetica, Product Sans Medium;
}

.summaryInfoMainText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-heading-text-color);
  margin: 0px;
  word-break: break-all;
}

.infoTextColor {
  font-family: Helvetica, ProductSans;
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-weight: 400;
  color: var(--body-text-color);
  margin: 0px;
  word-break: break-all;
}

.timelineTextBold {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
}
.timelineTextNormal {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
  min-height: 100px;
}
.timelineText1 {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 43px;
  text-align: left;
  color: var(--subHeadings-color);
}
.timelineText2 {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  padding-left: 43px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: var(--body-text-color);
}
.detailsInfoTextColor {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
  font-family: Helvetica, Product Sans Medium;
}
.datePickerBgColor {
  background-color: var(--input-color) !important;
}
.datePickerBgColor:focus-visible {
  background-color: var(--input-focus) !important;
}

.TagSelectedTags {
  color: var(--primaryColor);
  margin-top: 30px;
  border-radius: 18px;
  padding: 10px 20px 10px 20px;
  background-color: var(--secondary-card-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
}
.avtarBgClr {
  background-color: var(--secondary-card-color);
}

.copyLinkButton {
  height: 48px;
  width: 119px;
  border-radius: 6px;
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--theme);
  /* line-height: 19.41px; */
}

.copyLinkButton:hover {
  color: var(--primary-hover-button-color) !important;
}

.ButtonForNormalPrimary {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  color: var(--theme);
  height: 43px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
}
.ButtonForNormalPrimary:hover,
.ButtonForNormalPrimary:focus {
  color: var(--primary-hover-button-color) !important;
}
.ButtonForNormalSecondary {
  font-family: Helvetica, Product Sans Medium;
  height: 43px;
  width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  border-radius: 6px;
  font-style: normal;
  background: transparent;
}

.ButtonForNormalSecondary:hover {
  color: var(--secondary-hover-button-color) !important;
}

.sectionEndGap {
  margin-top: 45px;
}

.deleteRed {
  color: #df4242;
}
.calendarTitleText {
  color: var(--headings-color);
  font-family: Helvetica, ProductSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
.calendarSubTitleText {
  color: var(--body-text-color);
  font-weight: 400;
  font-family: Helvetica, ProductSans;
  font-size: 12px;
  line-height: 19px;
}
.calendarLinkText {
  color: var(--primaryColor);
  font-weight: 500;
  font-family: Helvetica, Product Sans Medium;
  font-size: 14px;
  line-height: 19px;
}

.textInsideTextArea {
  color: var(--body-text-color);
  font-family: Helvetica, ProductSans;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.notificationCard {
  height: 600px;
  width: 410px;
  right: 140px;

  border-radius: 8px;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  transform: translate(45px, 55px);
  position: absolute;
  /* overflow: hidden; */
  overflow-y: scroll;
  overflow-x: hidden;
}
/* .notificationDropdown { */
/* background-color: var(--theme); */
/* float: right; */
/* } */

.notificationCard::-webkit-scrollbar {
  width: 0px;
}

.notificationFullDetails {
  height: 600px;
  width: 558px;
  background-color: var(--theme);
  z-index: 100;
  /* transform: translate(45px,55px); */
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: 20px auto;
  overflow-y: scroll;
}
.notificationFullDetails::-webkit-scrollbar {
  width: 0px;
}
.rolesAndPermission {
  color: var(--body-text-color);
  font-weight: 500;
}
.marginTop {
  margin-top: 20px;
}
.rolesAndPermissionCard {
  margin: 30px !important;
}

.notificationBg {
  background-color: var(--notification--bg);
}
.notificationBg1 {
  background-color: var(--theme);
}

.notificationSubText {
  /* font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px; */
  color: #7c7c7c;
}

/* for externa job application  */
.externalJobTextdep {
  font-weight: 400;
  font-size: 14px;
  font-family: Helvetica, ProductSans;
  line-height: 17px;
  font-style: normal;
  color: var(--subHeadings-color);
}

.externalJobBlClr {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2f7bf5;
}

.externalJobTextrole {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: var(--body-text-color);
}

.externalJobLocationclr {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  color: #7c7c7c;
  line-height: 17px;
}

.externalFltName {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #030a16;
}

.JobTitleNamedp {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.26px;
  color: #030a16;
}
.cards {
  width: 190px;
  height: 128px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.19);
}
.recuritmentReportCardTitle {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 32px !important;
  text-align: center;
  margin-bottom: 16px;
}
.recuritmentReportCardSubTitle {
  font-style: normal;
  font-family: Helvetica, ProductSans;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  padding-bottom: 25px;
  color: var(--card-text-color);
}

.externJobDetailHeading {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  font-size: 16px;
  color: #030a16;
}

.externalPageBdyText {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  color: #403e3e;
}

.toCenterAvtarIconDiv {
  /* margin: 0px auto; */
  margin: 15% auto;
  text-align: center;
}
.toCenterAvtarIconSpan {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
}

.gapOnTop {
  margin-top: 20px;
}

/* end of extrenal job apllication  */

@media screen and (min-width: 320px) and (max-width: 480px) {
  /* .copyLinkButton {
    width: 60px;
    font-weight: 700;
    font-size: 12px;
  } */
  .toCenterAvtarIconDiv {
    margin: 15% auto;
  }

  /* .detailsInfoTextColor {
    font-size: 14px;
  } */
}
@media screen and (min-width: 481px) and (max-width: 766px) {
  /* .copyLinkButton {
    width: 60px;
    font-weight: 700;
    font-size: 12px;
  } */
  .toCenterAvtarIconDiv {
    margin: 0px auto;
  }

  /* .detailsInfoTextColor {
    font-size: 14px;
  } */
}
@media screen and (min-width: 767px) {
  /* .copyLinkButton {
    width: 60px;
    font-weight: 700;
    font-size: 12px;
  } */
  /* .toCenterAvtarIconDiv {
    margin: 15% auto;
  } */
  .buttonSettingsPrimary,
  .buttonSettingsSecondary {
    width: 126px;
  }

  /* .detailsInfoTextColor {
    font-size: 14px;
  } */
}

@media screen and (min-width: 767px) and(max-width:1023px) {
  /* .copyLinkButton {
    width: 119px;
  } */
  /* .searchBarAvtar {
    width: 50%;
  } */
  .toCenterAvtarIconDiv {
    margin: 0px auto;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  /* .copyLinkButton {
    width: 119px;
  } */
  .toCenterAvtarIconDiv {
    margin: 0px auto;
  }
}

@media only screen and (min-width: 280px) and (max-width: 360px) {
  .externApply {
    width: 100% !important;
  }
  .hipleExtApplyBtn{
    display: none;
  }
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
  .externApply {
    width: 100% !important;
  }
  .hipleExtApplyBtn{
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 576px) {
  .externApply {
    width: 100% !important;
  }
  .hipleExtApplyBtn{
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .externApply {
    width: 100% !important;
  }
  .hipleExtApplyBtn{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 820px) {
  .externApply {
    width: 100% !important;
  }
  .rmHipleExtApplyBtn{
    display: none;
  }

  .hipleExtApplyBtn{
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 912px) {
  .externApply {
    width: 100% !important;
  }
  .rmHipleExtApplyBtn{
    display: none;
  }

  .hipleExtApplyBtn{
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
  .externApply {
    width: 100% !important;
  }
  .rmHipleExtApplyBtn{
    display: none;
  }

  .hipleExtApplyBtn{
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .externApply {
    width: 50% !important;
  }
  .rmHipleExtApplyBtn{
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .externApply {
    width: 50% !important;
  }
  .rmHipleExtApplyBtn{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 2560px) {
  .rmHipleExtApplyBtn{
    display: none;
  }
}
