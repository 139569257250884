.flagPosition {
  float: right;
  margin-top: 18px;
}
.countryInput {
  padding: 0px !important;
}

.ant-drawer-body {
  padding-bottom: 0px !important;
}

.addcandidatetop {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.footerContainer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
.addcandidatebtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  margin-right: 19px;
}
.cancelbtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  border: 3px solid var(--primaryColor);
}
/*  */
.breadCrumContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addButton {
  font-size: 16px;
  line-height: 24px;
  height: 46px;
  width: 214px;
  border-radius: 6px;
  float: right;
}

.plusBtnIcon {
  margin-left: 5%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.addNewJobName {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 14%;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
.inttitle {
  color: var(--headings-color);
  margin-right: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  float: left;
}
.LabelName {
  color: var(--subHeadings-color);
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  font-family: Helvetica, "Product Sans Medium";
}
.recruitmentData {
  font-family: Helvetica, "Product Sans Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 16.98px;
}
.JobsTableHeading {
  color: var(--subHeadings-color);
  font-family: Helvetica, "Product Sans Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  padding: 0px;
  margin: 0px;
}
.tableContent {
  font-family: Helvetica, "Product Sans Medium";
  size: 14px;
  line-height: 16.98px;
}
.ContentText {
  color: var(--body-text-color);
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.98px;
  font-family: Helvetica, "Product Sans";
}
.ContentTextBlue {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.98px;
}

.summaryInfoMainText {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--headings-color);
  margin: 0px;
}
.modalText {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--headings-color);
  margin: 0px;
}
.infoTextColor {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--headings-color);
}
.infoTextColorBold {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--headings-color);
}
.infoTextForExperience {
  color: var(--body-text-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.paddingForInfo {
  padding: 0px 24px 24px 24px;
}
.addIconsAlig {
  text-align: center;
  width: 50%;
  display: block;
  position: relative;
  margin: 40px auto 0px auto;
}
.candidateSummaryLeftMainCont {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
.candidateSummaryLeftSubCont {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--headings-color);
  text-align: center;
}
.drawerFormText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--headings-color);
}
.personalDetails {
  padding-left: 16px;
}
.interviewHeading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--headings-color);
}
.interviewSubHeading {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--headings-color);
}
.savebtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  margin-right: 19px;
}
.interviewbtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  border: 3px solid var(--primaryColor);
}
.footerContainer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
.bulletingPoints {
  font-size: 14px;
  font-weight: 400;
  color: var(--headings-color);
}
.answersText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--headings-color);
}
.timelineText1 {
  padding-left: 43px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: var(--headings-color);
}
.timelineText2 {
  padding-left: 43px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: var(--headings-color);
}
.btnPrimary {
  font-size: 16px;
  height: 48px;
  width: 181px;
  border-radius: 7px;
}
.btnSecondary {
  background-color: transparent !important;
  font-size: 16px;
  height: 48px;
  width: 181px;
  border: 2px solid var(--primaryColor);
  border-radius: 7px;
  color: var(--primaryColor) !important;
}

.filtericonLabel {
  color: var(--headings-color);
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
}

.addJobLabel {
  padding: 0px;
  margin: 0px;
}

.jobInfoCard {
  padding: 24px;
  /* height: 778px;
  width:363px; */
  /* margin-right:5%; */
}

.jobInfoParagraph {
  color: var(--headings-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
}

/* .border {
  border-bottom: 1px solid gray;
} */
.jobDetailCardMinHeight {
  padding-top: 4px;
  padding-left: 24px;
}

.jobDetailCard {
  margin: 20px;
  /* margin-left: 20px; */
  /* padding: 20px; */
  /* height: 778px;
  width:702px; */
  /* height: 778px; */
  /* padding-top: 18px;
  padding-left: 24px; */
}
.jobDetailCard1 {
  /* margin-left: 20px; */
  /* padding: 20px; */
  /* height: 778px;
  width:702px; */
  /* height: 778px; */
  padding-top: 8px;
  padding-left: 24px;
}
.jobDetailsCardHeader {
  /* height: 778px;
  width:702px; */
  /* height: 778px; */
  border-radius: 6px;
}
.seperator {
  /* color:#E0E0E0;
  width: 696.07px; */
  border: 1px solid #d1d1d18a;
  margin-top: 10px;
}
.txtBlueClr {
  color: var(--primaryColor);
}

.txtBlueClrRequirement {
  color: var(--primaryColor);
  font-weight: 500;
  font-size: 20px;
  font-family: Helvetica, "Product Sans Medium";
  font-style: normal;
}
.jobDetailPageHeading {
  color: var(--headings-color);
  margin-bottom: 0px;
}

.viewJobButton {
  width: 180px;
  height: 48px;
  margin-top: 20px;
}

.TagsJobDetailPage {
  color: var(--primaryColor);
  margin-top: 5px;
  padding: 10px 20px 10px 20px;
  background-color: var(--secondaryColor);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
}

.FilterBtnName {
  color: var(--primaryColor);
  font-size: "16px";
  margin-left: 4px;
}

.ResetFilterBtn {
  width: 150px;
  height: 48px;
  background-color: white;
  border: 3px solid var(--primaryColor);
  font-size: 16px;
}

.ApplyFilterBtn {
  width: 150px;
  height: 48px;
  margin-left: 20px;
  font-size: 16px;
  text-align: center;
}

.BtnWhite {
  width: 150px;
  height: 48px;
  background-color: var(--theme);
  font-size: 16px;
  color: var(--primaryColor);
}
.BtnWhite:hover,
.BtnWhite:focus {
  border: none !important;
  background-color: transparent !important;
  color: var(--primaryColor);
}

.ButtonPrimary {
  height: 48px;
  width: 150px;
}

.modalRowStyling {
  padding: 0px 20px 0px 20px;
}

.expirytxt {
  font-size: 14px;
}

.recruitmentJobDetailCard {
  width: 363px;
  height: 816px;
  padding: 20px;
}
