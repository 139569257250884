.input {
  font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;

  font-size: 16px;
  color: var(--primaryColor) !important;
  border-radius: 8px;
  background-color: rgba(227, 235, 255, 0.9);
  border-width: 0px !important;
}
.submit {
  font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  height: 50px;
  border-radius: 8px;
  color: white;
  width: 100%;
  background-color: var(--primaryColor);
  margin-top: 25px;
}
.logo {
  width: 300px;
  margin-top: 40%;
  padding: 15px;
  padding-left: 0px;
}

.col {
  background-color: white;
}
.card {
  text-align: center;
  height: 100vh;
  background-color: var(--secondaryColor);
  border-radius: 0px 20px 20px 0px;
}
.formcol {
  background-color: white;
  float: left;
}
.p {
  font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  color: var(--primaryColor);
  width: 250px;
  margin: 10px auto;
  font-size: 28px;
}
.pmadewith {
  font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--primaryColor);
  width: 250px;
  margin: 20px auto;
  font-size: 20px;
  position: fixed;
  bottom: 0;
}
.pintell {
  font-family: Helvetica,Product Sans Medium;
  font-style: normal;
  line-height: 24px;
  color: var(--body-text-color);
  font-weight: 500;
  font-size: 20px;
  width: 250px;
  margin: 20px auto;
  float: right;
  position: fixed;
  bottom: 0;
  left: 24%;
}
.formCard {
  width: 500px;
  margin: 100px auto;
}
.inputIcons {
  padding: 9px;
  border-radius: 8px;
  background-color: rgba(227, 235, 255, 0.9);
}
.p1 {
  font-family: Helvetica,Product Sans Medium;
  font-style: normal;
  line-height: 44px;
  color: var(--headings-color);
  font-size: 36px;
  font-weight: 500;
}
.p2 {
  font-family: Helvetica,Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  color: var(--primaryColor);
  float: right;
}
.link {
  font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
  float: left;
}
.linkP {
  font-family: Helvetica,ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--primaryColor);
  float: left;
  margin-left: 3px;
}
.fp {
  font-family: Helvetica,ProductSans;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  color: var(--body-text-color);
  margin-bottom: 30px;
}
