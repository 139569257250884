@import "antd/dist/antd.variable.min.css";
@font-face {
  font-family: "ProductSans";
  src: local("ProductSans"), url("../fonts/ProductSans-Regular.ttf"),
    url("../fonts/ProductSans-Regular.woff");
}
@font-face {
  font-family: "Product Sans Medium";
  src: local("Product Sans Medium"), url("../fonts/ProductSans-Medium.ttf"),
    url("../fonts/ProductSans-Medium.woff");
}
@font-face {
  font-family: "Product Sans Light";
  src: local("Product Sans Light"), url("../fonts/ProductSans-Light.ttf"),
    url("../fonts/ProductSans-Light.woff");
}
body {
  font-family: ProductSans !important;
  color: var(--body-text-color);
}
/* react big calendar start */
.rbc-day-slot .rbc-time-slot {
  border: unset;
}
.rbc-timeslot-group {
  background-color: var(--theme);
  border-width: 1px;
  min-height: 182px !important;
  margin: 0;
  padding: 0;
  border-color: #d1d1d1;
}
/* .rbc-month-row {
  min-height: 200px !important;
} */
.rbc-event {
  border-radius: 5px !important;
  color: var(--theme) !important;
  border-left: 7px solid var(--primaryColor) !important;
  background-color: var(--theme) !important;
  box-shadow: 0px 0px 10px lightgray !important;
  /* margin: 5px auto !important;
  display: block !important;
  position: relative !important;
  width: 100% !important; */
}
.rbc-event {
  height: auto !important;
}
.rbc-event-label {
  display: none !important;
}
.fc-time-grid .fc-content-skeleton {
  height: 100%;
}
.rbc-toolbar-label,
.rbc-button-link,
.rbc-label {
  color: var(--body-text-color) !important;
}
.rbc-today {
  background-color: var(--input-color) !important;
}
/* .rbc-month-row + .rbc-month-row {
  min-height: 182px !important;
} */
/* .rbc-header {
  min-height: 85px !important;
} */
/* react big calendar end */
