.contentContainer {
  background-color: var(--theme);
  min-height: 225px;
  width: 100%;
  margin: 40px 0px;
  padding: 20px 30px;
  border-radius: 12px;
  overflow: hidden;
}

.contentContainer1 {
  background-color: var(--theme);
  min-height: 225px;
  width: 100%;
  margin: 10px 0px;
  padding: 20px 30px;
  border-radius: 12px;
  overflow: hidden;
}

.inttitle {
  margin-right: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  float: left;
  color: var(--headings-color);
}

.cards {
  width: 190px;
  height: 128px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--card-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.19);
}
.candidateSummaryCardTitle {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  font-size: 32px !important;
  text-align: center;
  margin-bottom: 16px;
}
.candidateSummaryCardSubTitle {
  font-style: normal;
  font-family: Helvetica, ProductSans;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  padding-bottom: 25px;
  color: var(--card-text-color);
}
.subHeadingTextNormal {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.jobSummaryContent {
  width: 100%;

  text-align: center;
}
.jobSummaryContentBar {
  height: 68px;
  position: relative;
  float: right;
  width: 8px;
  border-radius: 4px;
}
.jobSummaryContentBar1 {
  height: 80px;
  position: relative;
  float: right;
  width: 8px;
  border-radius: 4px;
}
.jobSummaryContent1 {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 25px;
  line-height: 39px;
}
.jobSummaryContent2 {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: Helvetica, ProductSans;
  line-height: 24px;
  color: var(--body-text-color);
}

.avtarForLogAndNotf {
  width: 50px;
  height: 50px;
  margin: 5px 13px;
  float: right;
  display: block;
}
.avtarForLogAndNotg {
  width: 50px;
  height: 50px;
  margin: 5px 13px;
  float: right;
  display: block;
}
.avtarBellForLogAndNotg {
  width: 50px;
  height: 50px;
}
.avtarForLogAndNotification {
  width: 40px;
  height: 40px;
  margin: 5px 13px;
  float: right;
  display: block;
}
.inputField {
  width: 80% !important;
  height: 42px !important;
  border-radius: 50px !important;
  margin: 10px 0px;

  float: left !important;
}
/* .tabsCss {
  background-color: #656464;
} */
.ant-tabs > .ant-tabs-nav {
  height: 450px !important;
  background-color: aqua !important;
}
