@import "antd/dist/antd.variable.min.css";

.label {
  font-size: 20px;
  font-weight: 500;
  color: var(--subHeadings-color);
}

.darkAndLightThemStatus {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  margin: 0px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19.41px;
  color: var(--body-text-color);
}
.emailAndWhatsappSubText {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-size: 14px;
  color: var(--body-text-color);
  font-weight: 300;
  line-height: 17px;
}
.disableAndEnableBtnStatus {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  padding-left: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.dataImportTitle {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  line-height: 24px;
  margin: 0px;
  font-size: 20px;
  font-weight: 400;
  color: var(--subHeadings-color);
  /* color: var(--body-text-color); */
}
.dataImportTableText {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  color: var(--body-text-color);
  /* color: var(--subHeadings-color); */
}
.formateInSettingsStyle {
  font-family: Helvetica, Product Sans Medium;
  /* color: var(--body-text-color); */
  color: var(--headings-color);
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.emailAndWhatsApp {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
}
.importTableText {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
  color: var(--subHeadings-color);
}
.label1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: -10px;
  color: var(--subHeadings-color);
}
.inputimg {
  border-radius: 7px;
  height: 170px;
  background-color: var(--input-color);
  padding: 20px;
}
.countryInput {
  padding: 0px !important;
}
.flagPosition {
  float: right;
  margin-top: 18px;
}
.link {
  color: var(--subHeadings-color);
  float: left;
}
.linkP {
  color: var(--primaryColor);

  float: left;
  margin-left: 3px;
}
.taskSummaryContentContainer {
  align-items: center;
  display: flex;
}

.taskSummaryContentContainer1 {
  align-items: center;
  display: flex;
}

.taskSummaryContentContainer1 p:nth-child(1) {
  flex: 2;
}
.contentContainer {
  padding: 0px 5px;
}
.p {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  width: 200px;
  color: var(--body-text-color);
}
.p1 {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.modelcol1 {
  padding: 0px 10px !important;
}
.addbtn {
  font-size: 18px;

  width: 100px;
  height: 40px;
  float: right;
  margin-right: 10px;
}
.addbtns {
  font-size: 18px;
  margin-top: 20px;
  width: 100px;
  height: 40px;
}
.cancelbtn {
  font-size: 18px;
  height: 40px;
  float: right;
  width: 100px;
  margin-right: 10px;
}
.savebtns {
  font-size: 18px;
  margin-top: 30px;
  width: 100px;
  height: 40px;
  margin-left: 5px;
}
.resetbtn {
  font-size: 18px;
  height: 40px;
  margin-top: 30px;

  margin-right: 5px;
}

.btndiv {
  float: right;
  display: flex;
  justify-content: end;
  margin-right: -10px;
  border-bottom: 0px solid #f0f0f0;
}
.btndiv1 {
  justify-content: end;
  margin-left: 10px;
  border-bottom: 0px solid #f0f0f0;
}
.tableIcons {
  margin-left: 10px;
}
.icons {
  font-family: Helvetica, Product Sans Light;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  font-style: normal;
  color: var(--body-text-color);
  margin-right: 10px;
}
.colorp {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 20px;
  /* color: var(--body-text-color); */
  color: var(--subHeadings-color);
  word-break: break-all;
}
.changcolrp {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--change-color);
  margin-top: 20px;
  word-break: break-all;
}
.choosecolrp {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--primaryColor);
  margin-top: 20px;
}
.colors {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}
.colorps {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #000000;
}
.colorps1 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #66686b;
}
.colorps2 {
  font-size: 16px;
  font-weight: 300;
  margin-top: -5px;
  color: #000000;
}
.addModal {
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
  color: var(--subHeadings-color);
  margin-top: 5px;
}
