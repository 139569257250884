@import "antd/dist/antd.variable.min.css";
.employeeText {
  color: var(--table-text-color);
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.98px;
}
.employeeNameText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--primaryColor);
}
.employeeNameSubText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.rolesHeadingText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  float: left;
  color: var(--headings-color);
}
.rolesSubHeading {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--subHeadings-color);
}
.rolesSellectAllLink {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--primaryColor);
}
.rolesCheckBoxText {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--inverse-body-text-color);
}
.rolesLeftCardText {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--body-text-color);
}
.marginBottomForText {
  margin-bottom: 8px !important;
}
.filterDrop {
  height: 37px;
  width: 106px;
  margin-right: 16px;
  float: left;
  font-size: 16px;
}

.flagPosition {
  float: right;
  margin-top: 18px;
}
.countryInput {
  padding: 0px !important;
}

.ant-drawer-body {
  padding-bottom: 0px !important;
}

.addcandidatetop {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.footerContainer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
.addcandidatebtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  float: right;
}
.cancelbtn {
  font-size: 18px;
  line-height: 27px;
  width: 180px;
  height: 48px;
  border: 3px solid var(--primaryColor);
}
.label {
  font-size: 18px;
  font-weight: 500;
  color: var(--headings-color);
}

.inputIcons {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.inputIconsF {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: -3px;
}
.inputIconsB {
  margin-top: -14px;
}
.p {
  font-size: 20px;
  font-weight: 300;
  color: var(--headings-color);
}
.p1 {
  font-size: 16px;
  font-weight: 500;
  color: var(--headings-color);
}
.div {
  display: flex;
}

.activeTagsStyleForEmployee {
  color: #044123;
  background-color: #d2efcd;
  font-style: normal;
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 14px;
  margin: 0px !important;
  line-height: 17px;
  padding: 6px 23px 6px 23px !important;
}

.inActiveTagsStyleForEmployee {
  background-color: #ffd9d9;
  color: #f83a3a !important;
  font-style: normal;
  font-family: Helvetica, Product Sans Medium;
  font-weight: 500;
  font-size: 14px;
  margin: 0px !important;
  line-height: 17px;
  padding: 6px 19px 6px 18px !important;
}
