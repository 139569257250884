p {
  font-size: 16px;
}
.tabRows {
  padding-top: 16px;
}

.cardHeight {
  height: 400px;
}
.resetPasswordBtn {
  width: 419px;
  height: 56px;
  color: var(--primaryColor) !important;
  background-color: var(--theme) !important;
  padding: 16px 31px 16px 16px;
  border: none !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}
.resetPasswordBtn:hover {
  color: white;
  background-color: var(--theme) !important;
  border: none !important;
}
.resetPasswordBtnName {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.logOutBtn {
  width: 419px;
  height: 60px;
  margin-top: 24px;
  border-radius: 8px;
  border: 3px solid var(--primaryColor);
  background: transparent;
  color: var(--primaryColor);
}
.logOutBtn:hover {
  background-color: transparent !important;
  border: 3px solid var(--primaryColor) !important;
}
.logoutPasswordBtnName {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--primaryColor);
}

.popUpInputField {
  width: 100%;
  margin-right: 0px !important;
}

.tabInfo {
  padding: 16px 24px 24px 24px;
  color: var(--subHeadings-color);
}
.tabInfo p {
  font-family: Helvetica, Product Sans Light;
  font-style: normal;
  padding: 0px 0px 10px 0px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}
.tabInfo b {
  font-family: Helvetica, Product Sans Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}

.modalTitle {
  margin: 0px;
  font-size: 20px;
  color: var(--headings-color);
}
.ModalInputFieldLabel {
  color: var(--headings-color);
}
.tabsTitle {
  padding-left: 13px;
  font-size: 20px;
}

.ant-modal-footer {
  padding-left: 24px;
}

.PersonalInform {
  font-family: Helvetica, ProductSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--body-text-color);
}

.popUpInputFieldLabel {
  color: var(--headings-color);
}
@media screen and (min-width: 767px) {
  .logOutBtn {
    width: 219px !important;
  }
  .resetPasswordBtn {
    width: 219px;
  }
  .logoutPasswordBtnName {
    font-size: 18px;
  }
}
@media screen and (min-width: 767px) and(max-width:1023px) {
  .logOutBtn {
    width: 219px !important;
  }
  .resetPasswordBtn {
    width: 219px;
  }
  .logoutPasswordBtnName {
    font-size: 24px;
  }
}
@media screen and (min-width: 1023px) {
  .logOutBtn {
    width: 419px !important;
  }
  .resetPasswordBtn {
    width: 419px;
  }
  .logoutPasswordBtnName {
    font-size: 24px;
  }
}
