@import "antd/dist/antd.variable.min.css";

.logo {
  height: 80px;
  padding-top: 10px;
  background-color: var(--sidemenu-bg-color);
}
.logoImage {
  position: relative;
  display: block;
  height: 80%;
  margin: 0px auto;
}

.logosOpen {
  border-radius: 0px 22px 0px 0px !important;
}
.logosClose {
  border-radius: 0px 0px 0px 0px !important;
}
/* .menuIcon {
  padding-top: 8px;
} */
.logo h1 {
  color: white;
}

.ant-menu-vertical > .ant-menu-item {
  border-radius: 50px;
}

.sideMenuBarRadius {
  border-radius: 0px 22px 0px 0px !important;
}
